// Fonts and Components
//import './App.css';
//import './components/css/fonts.css'
import BootstrapNavbar from "./components/navbar";

// Pages
import Home from './components/home.js';
import Services from './components/services.js'
import Portfolio from './components/portfolio.js'
import About from './components/about.js';
import ClientPortal from './components/client-portal';
import Canary from "./components/canary";
import Footer from './components/footer';

// React Components
import {Route, Router, Switch} from "react-router-dom";
import history from './history';

function App() {
  return (
    <div className="App">
        <BootstrapNavbar />
        <Router history={history}>
            <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/services" exact component={Services} />
                <Route path="/portfolio" exact component={Portfolio} />
                <Route path="/about" exact component={About} />
                <Route path="/client-portal" exact component={ClientPortal} />
                <Route path="/canary" exact component={Canary} />
            </Switch>
        </Router>
        {/*<Footer/>*/}
    </div>
  );
}

export default App;