import React from 'react'

export default class Canary extends React.Component {
    render() {
        return (
            <div className="App">
                <div className={"container"}>
                    {/* origin */}
                    <p>Gooseworks has not responded to any local, state, or federal warrants in calendar year 2024.</p>
                </div>
            </div>
        );
    }
}