import React from 'react'
import "../bootstrap/dist/css/bootstrap.min.css";

export default class Portfolio extends React.Component {
    render() {
        return (
            <div className={"App"}>
                <div className={"container"}>
                    <div className={"pb-1 border-bottom my-5"}>
                        <h1>Project History</h1>
                    </div>
                </div>
                <section id={"projects"}>
                    <h1>Under Construction</h1> {/*}
                    <div className={"accordion"} id={"accordion"}>
                        {projects.map((project) => (
                            <div className="accordion-item" key={project.index}>
                                <h2 className="accordion-header">
                                    <button className="accordion-button" type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target={'#collapse' + project.index}>
                                        { project.title }
                                    </button>
                                </h2>
                                <div id={'collapse' + project.index} className="accordion-collapse collapse show" data-bs-parent="#accordion">
                                    <div className="accordion-body">
                                        {project.description }
                                    </div>
                                </div>
                            </div>

                        ))}
                            </div> */}
                </section>
            </div>
        );
    }
}
